import React from "react"
import Link from "next/link"
import { Container, Row, Col, Button } from "react-bootstrap"
import Image from "./CustomImage"

const BottomBlock = (props) => {
  return (
    <section className="py-6 align-items-center  dark-overlay">
      {/* <section className="d-flex align-items-center dark-overlay"> */}
      <Image
        src={`/content/${props.bottomBlock.img}`}
        layout="fill"
        className="bg-image"
        alt="Hero image"
        loading="eager"
        priority={true}
      />
      <Container className="text-white overlay-content">
        <Row>
          <Col lg="6" className="mb-5 mb-lg-0 text-center text-lg-start">
            <p className="subtitle text-secondary">
              {props.bottomBlock.title}
            </p>
            <p className="text-lg">{props.bottomBlock.subTitle}</p>
            <p className="mb-0">{props.bottomBlock.content}</p>
          </Col>
          <Col
            lg="6"
            className="d-flex align-items-center justify-content-center"
          >
            <div className="text-center">
              <p className="mb-2">
                <Link href={props.bottomBlock.buttonLink}>
                  <Button
                    href={props.bottomBlock.buttonLink}
                    variant="secondary"
                    size="lg"
                  >
                    {props.bottomBlock.button}
                  </Button>
                </Link>
              </p>
              <p className="text-small">
                {props.bottomBlock.small}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default BottomBlock
