import React from "react"
import Link from "next/link"
import { Container, Row, Col } from "react-bootstrap"
import SwiperCategory from "./SwiperCategory"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons"

import data from "../data/categories-main.json"
import dataItems from "../data/categories-item.json"

const Categories = (props) => {
  return (
    <section className={`py-6 ${props.greyBackground ? " bg-gray-100" : ""}`}>
      <Container>
        <Row className="mb-5">
          <Col md="6">
            <p className="subtitle text-secondary">{data.subTitle}</p>
            <h2>{data.title}</h2>
          </Col>
          <Col
            md="6"
            className="d-lg-flex align-items-top justify-content-end"
          >
            {data.buttonLink && (
              <Link href={data.buttonLink}>
                <a className="text-muted text-sm">
                  {data.button}
                  <FontAwesomeIcon icon={faAngleDoubleRight} className="ms-2" />
                </a>
              </Link>
            )}
          </Col>
        </Row>
        <SwiperCategory
          className="swiper-container-mx-negative pt-3 pb-5"
          perView={1}
          spaceBetween={20}
          roundLengths
          md={2}
          lg={3}
          xl={4}
          data={dataItems.features}
          cards
          loop
          pagination
        />
      </Container>
    </section>
  )
}

export default Categories