import React from "react"
import Link from "next/link"

import { Card, Button, Row, Col } from "react-bootstrap"
import Image from "./CustomImage"
import Icon from "./Icon"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes, faLayerGroup } from "@fortawesome/free-solid-svg-icons"

const CardCategory = (props) => {
  const data = props.data
  console.log(props.data)
  return (
    <Card className="mb-4 mb-lg-0">
      <div className="card-status bg-primary" />
      <Card.Header as="h6" className="py-3 text-uppercase">
        {data.title}
      </Card.Header>
      <Card.Body className="d-flex  flex-column bd-highlight mb-1" style={{ height: '230px' }}>
        <Row className="p-0 bd-highlight">
          <ul className="list-unstyled mb-1 text-sm">
            {data.categories.map((item, index) => (
              <li key={index} className="mb-1">
                {/*<span className="fa-li text-secondary fa-ul">
                  <FontAwesomeIcon icon={faLayerGroup} />
                </span> */}
                <Link href={item.link}>
                  <a className="text-muted">
                    {item.name}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </Row>
        {data.link && (
          <div className="w-100 d-flex mt-auto p-0 bd-highlight border-top border-gray-dark pt-3">
            <Button
              href={data.link}
              variant="outline-primary"
              size="sm"
            >
              See all
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  )
}

export default CardCategory
