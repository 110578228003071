import React from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import { useCallback, useRef, useState } from 'react'

import { Row, Col, Form, Button } from "react-bootstrap"
import Select from "react-select"

import { faCrosshairs } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const SearchBar = (props) => {
  const searchRef = useRef(null)
  const [query, setQuery] = useState('')
  const [active, setActive] = useState(false)
  const [results, setResults] = useState([])
  const [searchType, setSearchType] = useState(props.options[0])
  const router = useRouter()

  const customSelectStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "0 solid #fff",
    }),
    indicatorSeparator: (provided, state) => ({
      display: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      color: "#272727",
      border: "0 solid #fff",
      boxShadow: "0 0 1.2rem rgba(0, 0, 0, .15)",
    }),
  }

  const searchEndpoint = (query) => `/api/search?q=${query}`

  const onFocus = useCallback(() => {
    setActive(true)
    window.addEventListener('click', onClick)
  }, [])

  const onSelectChange = useCallback((event) => {
    setSearchType(event)
  }, [])

  const onChange = useCallback((event) => {
    const query = event.target.value;

    setQuery(query)
    if (query.length > 2) {
      fetch(searchEndpoint(query))
        .then(res => res.json())
        .then(res => {
          const categories = res.categories.items
            .map((category) => ({
              id: category.id,
              code: 'CAT',
              name: category.name,
              url: `/companies${category.url}`
            }))
          const companies = res.companies.items
            .map((company) => ({
              id: company.id,
              code: 'COM',
              name: company.name,
              url: `/company/${company.vendor_url}`
            }))
          setResults([...categories, ...companies])
        })
    } else {
      setResults([])
    }
  }, [])

  const onClick = useCallback((event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      setActive(false)
      window.removeEventListener('click', onClick)
    }
  }, [])

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const qu = e.currentTarget.elements.search.value.trim()

    if (qu && qu.length > 2) {
      const href = `/search?q=${qu}`
      router.push(href)
    }
  }

  return (
    <div className={`search-bar ${props.className}`}>
      <Form onSubmit={handleOnSubmit}>
        <Row>
          <Col lg="6" className="d-flex align-items-center form-group">
            <Form.Control
              id="search"
              type="text"
              name="search"
              placeholder={searchType.value === 'COM' ? "What company are you looking for?" : "Which IT services are you looking for?" }
              className="border-0 shadow-0"
              onChange={onChange}
              onFocus={onFocus}
              autoComplete="off"
            />
          </Col>
          {/*  <Col
            lg="3"
            md={props.halfInputs ? "6" : "12"}
            className="d-flex align-items-center form-group"
          >
            <div className="input-label-absolute input-label-absolute-right w-100">
              <Label for="location" className="label-absolute">
                <i className="fa fa-crosshairs" />
                <span className="sr-only">City</span>
              </Label>
              <Input
                type="text"
                name="location"
                placeholder="Location"
                id="location"
                className="border-0 shadow-0"
              />
            </div>
          </Col> */}
          <Col
            lg="4"
            md={props.halfInputs ? "6" : false}
            className="d-flex align-items-center form-group no-divider"
          >
            <Select
              id="search_type"
              instanceId="search_type"
              options={props.options}
              //defaultValue={props.options[0]}
              isSearchable={false}
              className="selectpicker w-100"
              classNamePrefix="selectpicker"
              styles={customSelectStyles}
              value={searchType}
              onChange={onSelectChange}
              aria-label="Categories"
            />
          </Col>
          <Col 
            lg="2" 
            className={`d-grid ${props.btnMb ? `mb-${props.btnMb}` : ``}`}
          >  
            <Button
              type="submit"
              variant="outline-primary"
              className={`h-100 ${props.btnClassName ? props.btnClassName : ""
                }`}
            >
              Search
            </Button>
          </Col>
        </Row>
        {active && results.length > 0 && (
          <ul className='results'>
            {results
              .filter((f) => f.code === searchType.value)
              .map((item, index) => (
                <li className="result" key={index}>
                  <Link href={item.url}>
                    <a>{item.name}</a>
                  </Link>
                </li>
              ))}
          </ul>
        )}
      </Form>
    </div>
  )
}

export default SearchBar
